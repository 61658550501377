import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import axios from "axios";
import "../../../css/pages/contact_us/ContactUs.css";
import {
  errors,
  valid,
  refresh,
  Validator,
  submitValidator,
} from "../../common/Validator";
import Notifier from "../../common/Notifier";
import ErrorNotifier from "../../common/ErrorNotifier";
import { CONTACT_US } from "../../../utils/routes";

/**
 * @author
 * @class ContactUs
 **/

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      message: "",
      successMessage: "",
      error: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    refresh();
  }

  // onChange method to set the value entered by the user
  onChange = (e) => {
    Validator(e.target);
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  // onSubmit function to submitting the contact us form
  onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      businessConsulting: this.state.checkbox1,
      executiveCoaching: this.state.checkbox2,
      leadershipSkilling: this.state.checkbox3,
      additionalMessage: this.state.message,
      // createdBy: sessionStorage.getItem("id"),
    };
    if (valid) {
      axios
        .post(CONTACT_US, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            successMessage: res.data,
          });
          setTimeout(() => {
            this.setState({
              successMessage: "",
            });
          }, 2000);
        })
        .catch((error) => {
          this.setState({
            error: error.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      checkbox1,
      checkbox2,
      checkbox3,
      message,
      successMessage,
      error,
    } = this.state;
    return (
      <>
        <div className="contact-us-div-one">
          <h1>We would love to hear from you!</h1>
        </div>
        <div className="contact-us-form-div">
          <Row>
            <Col md="4">
              <h3>Talavvy Business Catalysts LLP.</h3>
              <p>contact@talavvy.com</p>
            </Col>
            <Col md="1"></Col>
            <Col md="7">
              <Form onSubmit={this.onSubmit} noValidate>
                <FormGroup>
                  <Label>Name *</Label>
                  <Row>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control-sm"
                        name="firstName"
                        value={firstName}
                        onChange={this.onChange}
                        required={true}
                      />
                      {errors.firstName ? (
                        <FormText className="error">
                          {errors.firstName}
                        </FormText>
                      ) : (
                        <FormText>First Name</FormText>
                      )}
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control-sm"
                        name="lastName"
                        value={lastName}
                        onChange={this.onChange}
                        required={true}
                      />
                      {errors.lastName ? (
                        <FormText className="error">{errors.lastName}</FormText>
                      ) : (
                        <FormText>Last Name</FormText>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label>Phone *</Label>
                  <Input
                    type="tel"
                    className="form-control-sm"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={this.onChange}
                    required={true}
                  />
                  {errors.phoneNumber && (
                    <FormText className="error">{errors.phoneNumber}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Email *</Label>
                  <Input
                    type="email"
                    className="form-control-sm"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    required={true}
                  />
                  {errors.email && (
                    <FormText className="error">{errors.email}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>I'm interested in help with... </Label>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="checkbox1"
                        checked={checkbox1}
                        onChange={this.onChange}
                      />
                      Business Consulting
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="checkbox2"
                        checked={checkbox2}
                        onChange={this.onChange}
                      />
                      Executive Coaching
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="checkbox3"
                        checked={checkbox3}
                        onChange={this.onChange}
                      />
                      Leadership Skilling
                    </Label>
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <Label>Additional Message(Optional)</Label>
                  <Input
                    type="textarea"
                    className="form-control-sm"
                    name="message"
                    value={message}
                    onChange={this.onChange}
                  />
                  {errors.message && <FormText>{errors.message}</FormText>}
                </FormGroup>
                <div className="center">
                  <button type="submit" className="btn login-button white-text">
                    Submit
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
          {successMessage && <Notifier message={successMessage} />}
          {error && <ErrorNotifier message={error} />}
        </div>
      </>
    );
  }
}

export default ContactUs;
