import React from "react";
import "../../css/common/Notifier.css";

function Notifier(props) {
  return (
    <div className="notification-box">
      <span className="notification">
        <i className="material-icons">offline_pin</i>
        {props.message}
      </span>
    </div>
  );
}
export default Notifier;
