import React from "react";
import { Row, Col } from "reactstrap";
import "../../../css/pages/home/OurOfferings.css";
import { Button } from "../../common/Button";

function OurOfferings() {
  return (
    <>
      <div className="our-offerings-wrapper">
        <div className="our-offerings-content">
          <Row className="our-offerings-row">
            <Col md="5" className="mx-auto our-offerings-col-heading">
              <h3>Our Offerings</h3>
            </Col>

            <Row>
              <Col md="3">
                <h4>
                  <em>
                    Digital <br />
                    Acceleration
                  </em>
                </h4>
                <p>
                  When deliberating between a process driven vs a data driven
                  approach, then we are here to help you make those critical
                  decisions and the right choices.
                </p>
              </Col>
              <Col md="3">
                <h4>
                  <em>Culture and People Change</em>
                </h4>
                <p>
                  We have deep domain expertise to build cultures which will
                  become your unique competitive advantage.{" "}
                </p>
              </Col>
              <Col md="3">
                <h4>
                  <em>Impactful Communication</em>
                </h4>
                <p>
                  We provide a new identity and value to your Marcom, Socialcom
                  and Corpcom functions
                </p>
              </Col>
              <Col md="3">
                <h4>
                  <em>
                    Interim <br />
                    Management
                  </em>
                </h4>
                <p>
                  We provide strategic leadership resources for organisations to
                  fill the performance vacuum and move ahead fast in all
                  transition events.
                </p>
              </Col>
            </Row>
          </Row>
          <Row className="learn-more-btn-row">
            <Col md="2" className="mx-auto">
              <Button buttonStyle="btn--background-white" to="/offerings">
                Learn More
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <Row className="our-offerings-row-two">
        <Col md="4" className="mx-auto ">
          <h1 id="our-offerings-row-two-h1">Let's Talk!</h1>
          <Button to="/contact-us">We'd Love To Hear From You</Button>
        </Col>
      </Row>
    </>
  );
}

export default OurOfferings;
