import React, {useEffect} from 'react'
import {Row, Col} from 'reactstrap'
import "../../../css/pages/blog/Blog.css"

function Blog() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    return (
        <>
            <div className="default-setting header-div">
                <h2>Blog</h2>
            </div>
            <div className="default-setting">
            <Row className="about-us-img-row">
                    <Col md="6" className="about-us-content-div">
                        <span><time>8/16/20</time></span>
                        <h1>Why do organizations need to accelerate Digital Adoption?
</h1>
                        <p className="text-justify">Digital Acceleration is the need of the hour. Many views exist that the onset of the pandemic has made organizations rethink their digital strategies and are being forced or being driven to the same. I believe the inevitable was just given a consciousness with the pandemic causing organizations to bring out their think tanks to evaluate digital adoption.</p>
                        <a href="/blog">Read More</a>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Blog
