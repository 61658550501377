import React from "react";
import "../../css/common/Footer.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import TalavvyLogo2 from "../../assets/img/talavvyLogoWhite.png";

function Footer() {

 const handleScroll = () => {
    window.scrollTo(0,0)
  }

  return (
    <>
      <div className="footer-wrapper">
        <Col md="11" className="mx-auto">
          <div className="footer-line"></div>
        </Col>
        <Row className="row-margin">
          <Col md="3">
            <img src={TalavvyLogo2} alt="Footer Logo" className="footer-logo" />
          </Col>
        </Row>
        <Row className="row-margin footer-link-row">
          <Link to="/" onClick={handleScroll}>
            <p>Home</p>
          </Link>
        </Row>
        <Row className="row-margin footer-link-row">
          <Link to="/about-us" onClick={handleScroll}>
            <p>About Us</p>
          </Link>
        </Row>
        <Row className="row-margin footer-link-row">
          <Link to="/offerings" onClick={handleScroll}>
            <p>Offerings</p>
          </Link>
        </Row>
        <Row className="row-margin footer-copyright-row">
          <p className="footer-copyright">
            Talavvy Business Catalysts LLP - Copyright © 2020. All rights
            reserved
          </p>
        </Row>
      </div>
    </>
  );
}

export default Footer;
