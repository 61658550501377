import React, {useEffect} from "react";
import "../../../css/pages/community/Community.css"
import communityImage from '../../../assets/img/community.png'

function Community() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return <>
  <div className="default-setting header-div">
                <h2>Community</h2>
            </div>
     <div className="default-setting community-img-div">
        <img src={communityImage} alt="Community" />
     </div>
     <div className="default-setting text-justify">
       <p>Talavvy's communities of experts, product houses,
                        solution provides and service delivery platforms from an
                        ecosystem that functions as an extended Think thank
                        platform for our clients. We converge on this market
                        place to find the best suited, need based derivative in
                        a lab like approach working in an integrated model with
                        our communities and Tribes. Our Communities and Tribes
                        are a selected, proven expertise and component growing
                        fraternity globally. Chosen from the experiences of
                        delivered results and each bring a war chest of
                        experience. The engagements are analyzed and the best
                        suited team is composed to form a client engagement.
                        Here in our marketplace is opportunity for the best to
                        provide the best at all times with a singular focus on
                        Transforming our clients Needs to Targets.</p>
     </div>
  </>;
}

export default Community;
