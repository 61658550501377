import React from 'react'
import "../../css/common/SocialIcons.css"

function SocialIcons() {
    return (
        <>
          <div className="social">
        <ul className="social-list">
        <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.linkedin.com/company/talavvy-business-catalysts/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-linkedin"
            >
              {null}
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://twitter.com/cherianreji"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-twitter"
            >
              {null}
            </a>
          </li>
          <li >
            <a
              style={{ textDecoration: "none" }}
              href="https://www.facebook.com/Talavvy-Business-Catalysts-LLP-111611420688772/"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-facebook"
            >
              {null}
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href=""
              // target="_blank"
              // rel="noopener noreferrer"
              className="fa fa-youtube-play"
            >
              {null}
            </a>
          </li>
          {/* <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://in.pinterest.com/d422588f0df26ec0b85279ce96db81/
              "
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-pinterest"
            >
              {null}
            </a>
          </li> */}
          
          
          
        </ul>
      </div>  
        </>
    )
}

export default SocialIcons
