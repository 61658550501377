import React, {useEffect} from "react";
import Hero from "./Hero";
import OurOfferings from "./OurOfferings";

function Home() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <Hero />
      <OurOfferings />
    </>
  );
}

export default Home;
