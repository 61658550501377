import React from "react";
import { Link } from "react-router-dom";
import "../../../css/pages/home/Hero.css";

function Hero() {
  return (
    <>
      <div className="hero-container">
        <div className="hero-text-container">
          <h1>
            Change is inevitable.
            <br />
            <em>Transformation is a choice.</em>
          </h1>
          <h4>
            <strong>Reimagine your business of today, for tomorrow. </strong>
          </h4>
          <h4>
            <strong>
              Accelerate the change to excel in your business, <br />
              reinvent and beat your targets.
            </strong>
          </h4>
          <h3>
            <Link to="/contact-us" className="hero-link">
              <em>Get In Touch</em>
            </Link>
          </h3>
        </div>
      </div>
      <section className="page-section">
        <div className="section-background">
          <div className="section-wrapper">
            <h3>
              Talavvy is a business transformation lab that employs research,
              experimentation, testing and expertise as channels of change. The
              insights gathered from the research are then used as the
              foundation for creating business strategies for our clients.
            </h3>
          </div>
        </div>
      </section>
      <div className="page-section-two"></div>
    </>
  );
}

export default Hero;
