import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { NavLink, Link } from "react-router-dom";
import "../../css/common/Navbar.css";
import logo from "../../assets/img/talavvyLogo.png";
import menuBar from "../../assets/icons/menu-bar.png";
import close from "../../assets/icons/close.png";
import { TALAVVYCOMMUNITY } from "../../utils/config";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [loginBtn, setLoginBtn] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    setLoginBtn(false);
  };
  const communityClick = () => {
    setClick(false);
    setLoginBtn(true);
  };

  const showButton = () => {
    if (window.innerWidth <= 1024) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo} alt="Talavvy" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <img src={click ? close : menuBar} alt="Menu" />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
              <NavLink
                to="/"
                exact
                className="nav-links"
                activeClassName="menu-active"
                onClick={closeMobileMenu}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about-us"
                className="nav-links"
                activeClassName="menu-active"
                onClick={closeMobileMenu}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/offerings"
                activeClassName="menu-active"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Offerings
              </NavLink>
            </li>
            {/*<li>
              <NavLink
                to="/community"
                className="nav-links"
                activeClassName="menu-active"
                onClick={communityClick}
              >
                Community
              </NavLink>
            </li>*/}
            <li>
              <NavLink
                to="/events"
                activeClassName="menu-active"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Events
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blog"
                className="nav-links"
                activeClassName="menu-active"
                onClick={closeMobileMenu}
              >
                Blog
              </NavLink>
            </li>
            {loginBtn && (
              <li>
                {" "}
                <NavLink
                  className="nav-links"
                  to=""
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(`${TALAVVYCOMMUNITY}`);
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Login
                </NavLink>{" "}
              </li>
            ) }
              <li>
                <NavLink
                  to="/contact-us"
                  className="nav-links-mobile"
                  onClick={closeMobileMenu}
                >
                  Contact Us
                </NavLink>
              </li>
          </ul>
          {button &&(
            <Button buttonStyle="btn--primary" to="/contact-us">
              Contact Us
            </Button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
