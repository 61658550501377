import React, {useEffect} from 'react'
import {Row, Col} from 'reactstrap'
import "../../../css/pages/about_us/AboutUs.css"
import digitalAcceleration from '../../../assets/img/digitalAcceleration.png'
import brandCom from '../../../assets/img/brandCom.png'
import peopleChange from '../../../assets/img/peopleChange.png'
import peopleChange2 from '../../../assets/img/peopleChange2.png'
import interimManagement from '../../../assets/img/interimManagement.png'

function Offerings() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <div className="default-setting header-div">
                <h2>Our Offerings</h2>
            </div>
            <div className="default-setting text-justify">
                <Row className="about-us-img-row">
                <Col md="5" className="offering-img-col">
                        <img src={digitalAcceleration} alt="Digital Acceleration" className="offerings-img"/>
                    </Col>
                    <Col md="8" className="offering-content-col">
                        <h3><strong>Digital Acceleration</strong></h3>
                        <p>When deliberating between a process driven vs a data driven approach, then we are here to help you make those critical decisions and the right choices.</p>
                        <p>Accelerating the digital footprint is not limited to SMAC (Social, Mobile, Analytics, Cloud), leveraging AI, practical deployment of IoT or Edge computing, it also requires driving process change, people transformation, culture and thought processes.</p>
                        <p>Talavvy, acts as the catalyst of change that encompasses all the aspects of Digital Transformation, by assimilation, verification and validation that are driven by metrics and KPIs, through an engaged collaboration model to unlock potential and accelerate the desired transformation outcomes. </p>
                    </Col>
                    <div className="about-us-row-line offerings-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
               
                    <Col md="8" className="offering-content-col">
                        <h3><strong>Communication - Corporate, Social and Marketing</strong></h3>
                        <p>A strong communication backbone allows a business to engage deeper with the stakeholders including Customers, Employees, Regulatory bodies, Investors & Channel partners.</p>
                        <p>Your marketing collateral design and internal communications enhance your media credibility resulting in a better brand reach. Content is the differentiator in marketing communications. At Talavvy, we back up quality content with robust research to build content value, through sharper briefs and propositions.</p>
                        <p>We are a team of experts who design effective communication to achieve maximum impact. We have the big agency experience supported by digital design ability. We provide a new identity and value to your Marcom, Socialcom and Corpcom functions.</p>
                    </Col>
                    <Col md="5" className="offering-img-col">
                        <img src={brandCom} alt="Communication - Corporate, Social and Marketing" className="offerings-img offering-right-img"/>
                    </Col>
                    <div className="about-us-row-line offerings-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
                <Col md="5" className="offering-img-col">
                        <img src={peopleChange} alt="Culture and People Change" className="offerings-img"/>
                    </Col>
                    <Col md="8" className="offering-content-col">
                        <h3><strong>Culture and People Change</strong></h3>
                        <p>Winning businesses are built on great cultures and people-first approach.</p>
                        <p>We have deep domain expertise to build cultures which will become your unique competitive advantage. Our research and practice backed experience across businesses enables us to create customised Enterprise Talent Solutions.</p>
                        <p>Talavvy partners in providing specialised services in Management Advisory, Leadership building, Organisation design, HR life cycle, and HR Tech. We leverage design frameworks to help CEO’s & Management Teams become successful by establishing a strong correlation between management team capabilities and business performance. </p>
                        <div>
                            <img src={peopleChange2} alt="People Change 2" className="offerings-sub-img" />
                        </div>
                        <p>Our proprietary culture tool Talavvy’s Square-of-C (culture, capability, contribution and credibility) measures culture changes in your journey of transformation. It can be applied to strengthen your existing culture, build a new culture or integrate into another culture.</p>
                    </Col>
                    
                    <div className="about-us-row-line offerings-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
                
                    <Col md="8" className="offering-content-col">
                        <h3><strong>Interim Management</strong></h3>
                        <p>There could be many reasons for a disruption faced by leaders and managers in business, say a turnaround, a new project, upgradation, sell-off or buy-out of business/es, strategic reasons or long gestation of hire, but the show must always go on.</p>
                        <p>Talavvy provides strategic leadership resources for interim management of organisations to fill the performance vacuum and move ahead fast in all transition events. They bring in expertise, experience and execution skills to ensure seamless continuity. We will help you with interim executives at senior leadership roles and advise how this will help you speed up achieving your business objectives. Our interim executives will take full role responsibility and report to the Client organisation. They can come in quickly and land well into the client’s culture and the role. We also provide a full management team as interim arrangement to manage a business or an asset class for a defined duration.</p>
                    </Col>
                    <Col md="5" className="offering-img-col">
                        <img src={interimManagement} alt="Pardeep Pahal" className="offerings-img offering-right-img"/>
                    </Col>
                    <div className="about-us-row-line offerings-row-line"></div>
                </Row>
                <div className="promise-div">
                    <h3>The Talavvy Promise:</h3>
                    <p>We will partner with you and co-create solutions that are distinct to you with a single objective of you meeting your business goals.  </p>
                </div>
            </div>
        </>
    )
}

export default Offerings
