import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import Navbar from "./components/common/Navbar";
import SocialIcons from './components/common/SocialIcons'
import Home from "./components/pages/home/Home";
import AboutUs from './components/pages/about_us/AboutUs'
import Offerings from './components/pages/offerings/Offerings'
import Community from "./components/pages/community/Community";
import Events from './components/pages/events/Events'
import Blog from './components/pages/blog/Blog'
import ContactUs from "./components/pages/contact_us/ContactUs";
import Footer from "./components/common/Footer";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <SocialIcons />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/offerings" exact component={Offerings} />
          <Route path="/community" exact component={Community} />
          <Route path="/events" exact component={Events} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/contact-us" exact component={ContactUs} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
