import React, {useEffect} from 'react'
import {Row, Col} from 'reactstrap'
import "../../../css/pages/about_us/AboutUs.css"
import rajeshPadmanabhanImage from '../../../assets/img/rajeshPadmanabhanImage.png'
import rejiThomasImage from '../../../assets/img/rejiThomasImage.png'
import mangeshImage from '../../../assets/img/mangeshImage.png'
import pradeepImage from '../../../assets/img/pradeepImage.png'
import ashishImage from '../../../assets/img/ashishImage.png'

function AboutUs() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <div className="default-setting header-div">
                <h2>About Us</h2>
            </div>
            <div className="default-setting header-description-div">
                <h4 className="text-justify">Talavvy is a business transformation lab that employs research, experimentation, testing and expertise as channels of change. The insights gathered from the research are then used as the foundation for creating business strategies for our clients. Through its consulting, coaching and skilling practices, Talavvy works towards creating smarter teams employing dependable technologies.<br /><br /><br />It is an enterprise play and focuses on transforming client organisations using their rich expertise across industries, sectors and geographies.</h4>
            </div>
            <div className="default-setting text-justify">
                <Row>
                    <Col md="6" className="our-values-col">
                        <h3>Our Values</h3>
                        <p>
                            <strong>Objectivity:&nbsp;</strong>
                            We will mine out the core insights and build future strategies on them.
                        </p>
                        <p>
                            <strong>Customer Care:&nbsp;</strong>
                            We strive to exceed our customer client expectations all the time.
                        </p>
                        <p>
                            <strong>Integrity:&nbsp;</strong>
                            We will uphold the highest levels of integrity in our actions.
                        </p>
                        <p>
                            <strong>Fun:&nbsp;</strong>
                            We love positive energy & believe happy people are more efficient and creative. 
                        </p>
                        <p>
                            <strong>Innovation:&nbsp;</strong>
                            We create innovative solutions and convert them to positive outcomes.
                        </p>
                    </Col>
                    <Col md="6" className="our-values-col">
                        <h3>Our Mission: Transformation to Targets</h3>
                        <p>We believe, Continuous Transformation is the unique differentiator of winners in business. Team Talavvy will help you achieve the dream set for yourself through Consulting, Coaching and Skilling. We have the ability to build customised products and solutions for you to stay ahead in the game.</p>
                    </Col>
                </Row>
                <div className="our-team-div">
                    <h3>Our Team</h3>
                    <p>We are a specialised team with diverse backgrounds, who lead by example and demonstrate passion for what we do.</p>
                </div>
                <Row className="about-us-img-row">
                    <Col md="6" className="about-us-content-div">
                        <h3><strong>Rajesh Padmanabhan</strong></h3>
                        <p><strong>CEO</strong></p>
                        <p>A 6 by 6 matrix global professional having done six roles in six different industries. A Double Masters in Human Resource’s & Finance, he started his career handling systems on mainframes & went on to become a corporate banker, run P&L’s, execute Corporate Strategy, do new business startup, SAP Functional, Business Consulting & Global HR. Built convergence between Business & People keeping Technology at the forefront. He has been in global roles for over 2 decades and managed diverse teams across  North America, Latin America, Europe, Africa, Middle East, SE Asia & APAC. </p>
                        <p>He is passionate about leadership development, people transformation & skills building holistically for business value & excellence creation in his various roles with ICICI Group, Essel Propack as Global CHRO and with The Oberoi Group as EVP heading Group HR & OCLD ( Oberoi Centre for Learning & Development).</p>
                        <p>As EVP & Global CHRO at IGate - Patni Computer Services ( now part of Capgemini) he created customer – centric global HR models across geographies with world class learning skills, building frameworks & running complex yet efficient structures to create customer value. </p>
                    </Col>
                    <Col md="6">
                        <img src={rajeshPadmanabhanImage} alt="Rajesh Padmanabhan" className="about-us-img"/>
                    </Col>
                    <div className="about-us-row-div">
                        <p>He was responsible for Corporate Strategy across the group as member of the Management Board. Global mind-set, Influencing skills, & utilising technology is what he used as aspects to chart a new graph in his role at Capgemini, where he was Group CVP, Business Consulting & CHRO, India from 2010 to 2014. </p>
                        <p>Won the prestigious UN Award for building world class diversity & inclusive practices. Won large Customer Deals using people scale up plus skills model. Was part of the select Group CEO’s select TeamOne. As Group CHRO, Vedanta Resources, he created the platform for enabling businesses with cost-effective strategies, culture building, leadership effectiveness, driving people growth with a clear vision, capability development & high engagement levels across the group. As Director and Group CHRO at Welspun Group he lead Group HR, Corporate Centre, drove Cultural Change , Readied Digital footprint, Supported  Businesses with Strategy, Innovation and Transformation.</p>
                        <p>His hobbies include Cricket, Fitness, Motorcycles, Travelling & Research.</p>
                    </div>
                    <div className="about-us-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
                <Col md="6">
                        <img src={rejiThomasImage} alt="Reji Thomas Cherian" className="about-us-img"/>
                    </Col>
                    <Col md="6" className="about-us-content-div">
                        <h3><strong>Reji Thomas Cherian</strong></h3>
                        <p><strong>CTO</strong></p>
                        <p>A Transformational Consultant and a Business Coach are what describes Reji in summary. Specialises in Strategy, Mergers & Acquisitions, Due diligence, Post Merger integration, Operational Processes Simplification and Restructuring Advisory services.</p>
                        <p>He was the Head of SQS India and drove business growth for India and <br />Industrialisation for SQS Group globally. He specialised in software quality and set up a Software Centre for Assurance Services across verticals of BFSI, Auto and Manufacturing. Prior to SQS, he was Senior Vice President heading the North America unit for Capgemini India and the Sector Head for Telecom globally. The NA unit lead role exposed him to multiple industries like Consumer Retail & Distribution, Manufacturing, Life Sciences, Hi Tech, Media & Entertainment, Energy, Utilities and Chemicals. Prior to the Capgemini role, he was a member of the board and Executive Director of T-Systems in India, the system integrator of the Deutsche Telekom Group. He started his career with Siemens Group and spent 15 years in various roles and his last responsibility was CTO for the Siemens Shared Services.</p>
                    </Col>
                    
                    <div className="about-us-row-div">
                        <p>He is an Engineer in Computer Science and has undergone the HBS leader program, Certified Coach, and Certified Siemens Change Agent trained at Instead, MIT- Sloan-Boston, Stanford and IIM Bangalore.</p>
                        <p>Reji is a speaker and engaged with both industry and academia on multiple forums ranging from Telecom to Business School symposiums. He has been part of IT initiatives of the state governments. His hobbies also include wildlife preservation, mentoring - orphanages and adoption centres, education development in rural areas and a voracious reader.</p>
                    </div>
                    <div className="about-us-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
                    <Col md="6" className="about-us-content-div">
                        <h3><strong>Mangesh Borse</strong></h3>
                        <p><strong>CMO</strong></p>
                        <p>Mangesh Borse is a media entrepreneur, educator and a new media communication specialist, A BE(Mech, 1984) and a MMS(Marketing, 1986) from Mumbai University, Mangesh has cut his teeth in advertising, media and management education for more than 34 years in account management, media ownership business and academics.</p>
                        <p>His advertising career in account management was built with top agencies / brands like Mudra(Reliance Cup ‘87), Leo Burnett(P&G franchises like Ariel, Vicks and Tempo), Ogilvy(Cadbury’s Perk, Huggies and Kotex), Madison(P&G’s Ariel, Vicks and Whisper) and Lowe Lintas(Hamara Bajaj, Chetak, HSBC and BPCL). He turned entrepreneur when he was a Vice President and Unit Head of Mumbai V, setting up an outdoor media ownership company Symbiosis Advertising Pvt Ltd.</p>
                        <p>Symbiosis Advertising is a prominent outdoor media company owning some prime media assets in mumbai. Mangesh Borse is also an active advisor to the Board of Indian Outdoor Advertising Association. </p>
                    </Col>
                    <Col md="6">
                        <img src={mangeshImage} alt="Mangesh Borse" className="about-us-img"/>
                    </Col>
                    <div className="about-us-row-div">
                        <p>As an academic, Mangesh Borse is a designated Professor with the University of Mumbai. He has been conducting courses in Brand Management, Digital Marketing, Integrated Marketing Communications and Strategy over the last 30 years at Welingkar and other Management Institutes. Mangesh has been trained in case study methodology at the Harvard Business School Massachusetts, US and Shanghai, China in 2010-11. He has served as the Dean for Media & Entertainment at Welingkar Institute of Management</p>
                        <p>He loves cricket and is a keen follower of Indian Music- both Bollywood and Hindustani classical. He is currently learning about Indian ragas. Mangesh is passionate about regional theatre. He prefers reading non-fiction.</p>
                    </div>
                    <div className="about-us-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
                
                    <Col md="6" className="about-us-content-div">
                        <h3><strong>Pardeep Pahal</strong></h3>
                        <p><strong>Partner & Talent Specialist</strong></p>
                        <p>Pardeep is a senior HR leader, executive coach, OD & talent SME and a leadership builder who has considerable experience of people transformation in diverse organisations and across industries ie, Manufacturing, Pharma, IT services & FMCG. His last assignment was as HR Head for Capgemini Business Services in India. Prior to that he was head of Talent Management & Leadership Development for Capgemini India. He has handled a lot of general and HR specialist roles  in corporate HR, HR operations, Employee relations across industries as he built his profile into becoming a specialist talent  and change manager.</p>
                        <p>A graduate in science from the Delhi university, MBA from IMT Ghaziabad, MA is social work and an LLB with specialisation on labour laws. He is a certified Executive Coach and has received a lot of accolades over his 25 years career.</p>
                        <p>His hobbies are music, poetry, travelling and running.</p>
                    </Col>
                    <Col md="6">
                        <img src={pradeepImage} alt="Pardeep Pahal" className="about-us-img"/>
                    </Col>
                    <div className="about-us-row-line pradeep-row-line"></div>
                </Row>
                <Row className="about-us-img-row">
                <Col md="6">
                        <img src={ashishImage} alt="Ashish Mantri" className="about-us-img"/>
                    </Col>
                    <Col md="6" className="about-us-content-div">
                        <h3><strong>Ashish Mantri</strong></h3>
                        <p><strong>CFO</strong></p>
                        <p>Ashish, a practising Chartered Accountant is the managing partner of  Choudhury & Jain, a CA firm, since 2004 and supports his clients in the areas of Virtual CFO Services, Governance & Compliance, Risk Management, Assurances & Startup Mentoring. He specialises in Business Process Optimisation, Capital structuring, Risk & Control Assurance processes and advises Clients ranging from MNCs, MSMEs to Not for profit enterprises. He is also a founder member of a think-tank “Pharos CFO EDGE”.</p>
                        <p>He brings a fine mix of over 3 decades, comprising of hands-on experience of managing F & A functions at Corporate and operating his own practice. His corporate stint included CFO of Dodsal, Chief Finance Adviser with Dreamworks Group and Corporate Finance lead role in Rama Phosphates.</p>
                        <p>He is an avid traveller, nature enthusiast, a passionate photographer, philatelist and a cook.</p>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AboutUs
